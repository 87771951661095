.sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    width: 255px;
    background-color: #141414;
    position: fixed;
    height: 100%;
    overflow: auto;
    scrollbar-width: none;
    overscroll-behavior: contain;
    border-right: 4px solid #0a0a0a;
}

.sidebar::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
}

.sidebarLogo {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 66px;
}

.sidebar ul {
    list-style-type: none;
}

.sidebar a {
    display: block;
    color: #ffffff;
    padding: 12px;
    text-decoration: none;
    border-radius: 8px;
}

.sidebar a.active {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
}

.sidebar a:hover:not(.active) {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
}

.sidebar .disable-hover:hover {
    background-color: none !important;
    background: none !important;
    pointer-events: none;
    cursor: default;
}

.disable-hover div {
    cursor: default;
}

.sidebarLogo a.active {
    background-color: transparent;
}

.sidebarLogo a:hover:not(.active) {
    background-color: transparent;
}

div.content {
    margin-left: 255px;
    padding: 0;
    /* height: 1000px; */
}
.sidebarHeading {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    color: #ffffff80;
    padding: 0 1rem;
}

.sidebarSection {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
}

.sidebar-divider {
    height: 2px;
    background: #333333;
    width: 85%;
    margin: 0px auto;
    flex-shrink: 0;
}

.sidebarSection a {
    width: 100%;
}

nav {
    width: 100%;
}
.sidebar svg {
    width: 24px;
    height: 24px;
}

@media screen and (max-width: 990px) {
    .sidebar {
        display: none;
    }
    .sidebar a {
        float: left;
    }
    div.content {
        margin-left: 0;
    }
}

@media screen and (max-width: 400px) {
    .sidebar a {
        text-align: center;
        float: none;
    }
}

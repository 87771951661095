* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Readex Pro', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    scroll-behavior: auto !important;
}

a {
    text-decoration: none;
}

.slick-dots li {
    width: auto;
    margin: 0 2px;
}
.slick-dots li button {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 0 none;
    background-color: #fafafa;
    opacity: 0.3;
    padding: 0px;
}

.slick-dots li.slick-active button {
    opacity: 1;
}

.slick-dots li button::before {
    content: '';
}

@media (min-width: 992px) {
    #root {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.modal-content {
    -webkit-appearance: none;
    background-color: #000000 !important;
    border: 1px solid #000000 !important;
}
.modal-backdrop.show {
    opacity: 0.7 !important;
}

.slider {
    -webkit-appearance: none;
    appearance: none;
    background-color: #5b5b5b;
    height: 2px;
    background-image: linear-gradient(#fad810, #fad810);
    background-repeat: no-repeat;

    transition: height 0.1s linear;
    cursor: pointer;
}

.slider:hover {
    height: 5px;
}

.slider::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    overflow: visible;
    cursor: pointer;
    background: #fad810;
    opacity: 0;
    transition: opacity 0.1s linear;
}

.slider::-moz-range-thumb {
    appearance: none;
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    overflow: visible;
    cursor: pointer;
    background: #fad810;
    border: none;
    opacity: 0;
    transition: opacity 0.1s linear;
}

.slider-container:hover .slider::-webkit-slider-thumb {
    opacity: 1;
}

.slider-container:hover .slider::-moz-range-thumb {
    opacity: 1;
}

.mobile-slider {
    background-image: linear-gradient(#fff, #fff);
}

.slider-fullscreen {
    appearance: none;
    -webkit-appearance: none;
    background-color: #949494;
    height: 4px;
    background-image: linear-gradient(#fff, #fff);
    background-repeat: no-repeat;
}

.slider-fullscreen::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: #fff;
    cursor: pointer;
    border-radius: 50%;
}

.slider-fullscreen::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: #fff;
    cursor: pointer;
    border-radius: 50%;
}

.registration-confirm-modal .modal-content {
    color: white;
    background-color: #202020 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.registration-confirm-modal .modal-header {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    border: none;
}

.registration-confirm-modal .modal-body {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.5);
}

.registration-confirm-modal .modal-footer {
    border: none;
    margin-bottom: 15px;
}

/* hide arrow in number input */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}
.progress-bar {
    background-color: #fad810 !important;
}

ul.ant-dropdown-menu.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
    background-color: #202020;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;
}
li.ant-dropdown-menu-item {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    color: #fafafa !important;
}

/**checkbox design**/
.check-container {
    display: block;
    position: relative;
    padding-left: 27px;
    margin-bottom: 24px;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #0b0333;
}

/* Hide the browser's default checkbox */
.check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.check-container input ~ .checkmark {
    background-color: transparent;
    border: 1px solid #79829a;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
    background-color: #79829a;
}
.check-container input:checked ~ .checkmark {
    background: #fad810;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
    left: -2px;
    top: -1px;
    background-image: url('/img/check.svg');
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background-position: center;
    width: 22px;
    height: 20px;
    background-repeat: no-repeat;
    transform: rotate(-10deg);
}

.reminder-radio {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
}
.w-40.btn-design {
    width: 310px;
}

.reminder-radio input {
    opacity: 0;
}
.reminder-radio .contai {
    display: block;
    position: relative;
    padding-left: 27px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 10px;
    margin-bottom: 20px;
}

/* Create a custom radio button */
.reminder-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: unset;
    border: 2px solid #79829a;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.reminder-radio:hover input ~ .checkmark {
    background-color: #090909;
}

/* When the radio button is checked, add a blue background */
.reminder-radio input:checked ~ .checkmark {
    background-color: #2a3657;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.reminder-radio .checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.reminder-radio input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.reminder-radio .checkmark:after {
    top: 1px;
    left: 1px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #fad810;
    border: 3px solid #090909;
}
.reminder-radio li {
    list-style: none;
    float: left;
}

input[type='file'] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #fbd749;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 4px;
    width: 158px;
}

.select_location {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;

    width: 100%;

    background-color: #1f1f1f;
    border: none;
    color: #cccccc;
    -webkit-text-fill-color: #cccccc;
    opacity: 1;

    flex: none;
    order: 1;
    flex-grow: 0;
}
.select_location:focus-visible {
    outline: none;
}
.edit-select .ant-select {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 100%;
    background-color: #1f1f1f;
    border: none;
    color: #cccccc;
    -webkit-text-fill-color: #cccccc;
    opacity: 1;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.edit-select .ant-select-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 100%;
    background-color: #1f1f1f !important;
    border: none !important;
    color: #cccccc;
    -webkit-text-fill-color: #cccccc !important;
    opacity: 1;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.container_style {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}
.input_style {
    width: 100% !important;
    height: 48px !important;
    background: #333333 !important;
    border: none !important;
    color: #ffffff !important;
}
.ant-modal-content {
    background-color: #1f1f1f !important;
}
.ant-modal-close {
    color: #ffffff !important;
}
.ant-modal-header {
    background-color: #1f1f1f !important;
}

.ant-modal-title {
    color: #ffffff !important;

    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 140% !important;
}

.ant-collapse-header {
    font-family: 'Readex Pro' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 125% !important;
    color: #ffffff !important;
}
.ant-collapse {
    width: 100% !important;
}
.leaderboard-infinite-scroll-container {
    width: 100% !important;
}

div.campaign-description img {
    border-radius: 8px;
}

.slick-track {
    display: flex !important;
    gap: 10px;
}

.react-player-video {
    position: absolute;
    top: 0;
    left: 0;
}

@media (min-width: 991px) {
    .react-player-video {
        position: unset !important;
        top: unset !important;
        left: unset !important;
    }
}

.modalStyle .ant-modal-content {
    background-color: #141414 !important;
    padding: 32px 48px !important;
}

.modalStyle .ant-modal-header {
    background-color: #141414 !important;
    margin-bottom: 16px !important;
}
.modalStyle .ant-modal-close {
    top: 32px !important;
    inset-inline-end: 48px !important;
}

.login-modal .ant-modal-content {
    border-radius: 12px !important;
    background: #292929 !important;

    border: 1px solid var(--Surface-Disabled, #5b5b5b) !important;
    backdrop-filter: blur(2px) !important;
}

.coin-detail-modal .ant-modal-content {
    border-radius: 12px !important;
    background: #1f1f1f !important;
}

.voucher-input::placeholder {
    color: #8c8c8c !important;
}

.voucher-input::-ms-input-placeholder {
    color: #8c8c8c !important;
}

.subscription-modal .modal-content {
    border-radius: 32px;
    background: #202020;
    background-color: #202020 !important;
    width: fit-content;
    border: none !important;
}

.subscription-modal .modal-body {
    border-radius: 16px;
    background: #202020;
}

.modal-dialog {
    justify-content: center;
}

.ant-notification-notice-icon {
    display: none;
}

.ant-notification-notice-close-icon {
    display: none;
}

.ant-notification .ant-notification-notice {
    padding: 10px;
    text-align: center;
}

.new_user_toast {
    text-align: left !important;
}

.ant-notification-notice-message {
    font-weight: 600;
    margin-top: 8px;
}

.ant-slider .ant-slider-handle:focus::after {
    box-shadow: none;
    outline: none;
}

.ant-slider .ant-slider-handle:hover::after {
    box-shadow: none;
    outline: none;
}

.ant-slider .ant-slider-handle::after {
    box-shadow: none;
    outline: none;
}

.player-menu-content {
    color: #dedede !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.5px;
    letter-spacing: 0.1px;

    display: flex;
    gap: 6px;
    align-items: center;

    height: 32px;
    max-width: 200px;
}

.player-menu-content span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ant-slider:hover .ant-slider-track {
    background: #fad810 !important;
}

.ant-slider:active .ant-slider-track {
    background: #fad810 !important;
}

.ripple-button {
    position: relative;
    /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); */
    overflow: hidden;
    cursor: pointer;
    outline: 0;
    z-index: 1;
    transition: all 0.15s ease-in;
}

@keyframes ripple {
    0% {
        width: 0;
        height: 0;
        opacity: 0.5;
    }

    100% {
        width: 150px;
        height: 150px;
        opacity: 0;
    }
}

.ripple {
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: currentColor;
        visibility: hidden;
        z-index: 2;
    }

    &:not(:active):before {
        animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
        transition: visibility 0.4s step-end;
    }

    &:active:before {
        visibility: visible;
    }
}

/* datepicker */

.filter-date-picker .rdrDefinedRangesWrapper {
    display: none;
}

.filter-date-picker .rdrDateDisplayWrapper {
    background: transparent;
}

.filter-date-picker .rdrCalendarWrapper,
.filter-date-picker .rdrDateRangeWrapper {
    background: transparent;
}

.filter-date-picker .rdrDayNumber span {
    color: white;
}

.filter-date-picker .rdrMonthPicker select,
.filter-date-picker .rdrYearPicker select {
    color: white;
}

.filter-date-picker .rdrDateDisplayWrapper {
    display: none;
}

.filter-date-picker .rdrDayDisabled {
    background-color: #282828;
}

.filter-date-picker .rdrDayDisabled .rdrDayNumber span {
    color: #4b4b4b;
}

.ant-modal {
    font-family: 'Readex Pro' !important;
}

.ant-drawer {
    font-family: 'Readex Pro' !important;
}

.ant-popover-inner {
    background: #333 !important;
}

.ant-popover-arrow {
    opacity: 0;
}

.otp-select .ant-select-selector {
    width: 60px !important;
}

.otp-select .ant-select-dropdown {
    width: auto;
}

.otp-select .ant-input-group-addon {
    background: #1f1f1f;
    border: 1px solid #5b5b5b;
    color: #fff;
}

.otp-select .ant-input-affix-wrapper {
    background: #1f1f1f;
    border: 1px solid #5b5b5b;
    color: #fff;

    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.15px;
}

.otp-select .ant-input-wrapper {
    height: 40px;
}

.otp-select input {
    color: var(--Text-Heading---Primary, #fff);

    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
    height: 100%;
}

.otp-select .ant-input {
    background: #1f1f1f;
    border: 1px solid #5b5b5b;
    color: #fff;
    border-inline-start: 0;
}

.otp-select .ant-input::placeholder {
    color: grey;
    opacity: 0.5;
}

.otp-select .ant-select-arrow {
    color: #949494;
    position: absolute;
    left: 40px;
    size: 24px;
}

.otp-select .ant-input-affix-wrapper {
    height: 40px;
}

.otp-select .ant-input-affix-wrapper:hover {
    box-shadow: none;
    outline: none;
    border-color: #5b5b5b;
}

.otp-box-container {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 16px;
}

.otp-box {
    border-radius: 4px;
    background: var(--vb-ref-color-neutral30, #1f1f1f);
    min-width: 56px;
    height: 56px;
    color: white;
    border: none;

    color: var(--vb-ref-color-neutral100, #fff);
    text-align: center;

    /* vb/ref/typescale/headline-small-semibold */
    font-family: 'Readex Pro';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 30px */
}

.otp-box:active {
    border: 1px solid white;
    outline: none;
    box-shadow: none;
}

.otp-box:focus {
    border: 1px solid white;
    outline: none;
    box-shadow: none;
}

.otp-error .otp-box {
    border: 1px solid #ff7e7e;
}

.shake {
    animation: shake-animation 800ms cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake-animation {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.ant-drawer-content {
    background: none !important;
}

.custom-placeholder ::placeholder {
    /* Change placeholder color to #949494 */
    color: #949494 !important;
}

.ant-input-group .ant-input-group-addon {
    position: relative;
    padding: 0 0 0 11px;
    color: rgba(0, 0, 0, 0.88);
    font-weight: normal;
    font-size: 14px;
    text-align: start;
    border-radius: 6px;
    transition: all 0.3s;
    line-height: 1;
}

.ant-input-group .ant-input:focus {
    z-index: 1;
    border-inline-end-width: 1px;
    box-shadow: none;
}

.custom-dropdown .ant-select-item-option-active {
    background-color: #333333 !important; /* Set the background color */
    color: #ffffff; /* Set the text color */
}

.custom-dropdown .ant-select-arrow {
    margin-left: 8px;
}

.custom-select-option.ant-select-item-option-selected {
    background-color: #292929 !important;
}

.subscription-faq .accordion-button {
    background: #1f1f1f !important;
    color: white !important;
    outline: none !important;
    box-shadow: none !important;
}

.subscription-faq .accordion-button::after {
    background-image: url('/img/accordion-nav.svg');
    transform: rotate(180deg);
}

.subscription-faq .accordion-button:not(.collapsed)::after {
    background-image: url('/img/accordion-nav.svg');
    transform: rotate(0deg);
}

.subscription-faq .accordion-item {
    border: none;
}
.parity-badge {
    object-fit: none !important;
    width: auto !important;
    aspect-ratio: unset !important;
}
